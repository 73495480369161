import { FC, useEffect, useRef } from "react";
import { RegionRecommendationsMapI } from "./RegionRecommendation.interface";
import { Box } from "@mui/material";
import { Polygon } from "ol/geom";
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { defaults as defaultControls } from "ol/control";
import { boundingExtent } from "ol/extent";
import { useTranslation } from "react-i18next";

const sutiabilityColors = {
  good_match: "rgba(112, 173, 71, 0.5)",
  medium_match: "rgba(91, 155, 213, 0.5)",
  no_match: "rgba(255, 0, 0, 0.5)",
};

const satelliteGoogle = new TileLayer({
  visible: true,
  source: new XYZ({
    url: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  }),
});

export const RegionRecommendationMap: FC<RegionRecommendationsMapI> = ({
  preferences,
  boundaries,
}) => {
  const mapRef = useRef<Map>();
  const domRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const renderedSuitabilityColors = Object.entries(sutiabilityColors).map(
    ([key, color]) => {
      return (
        <Box display="flex">
          <Box
            mr={1}
            sx={{ bgcolor: color, width: "20px", height: "20px" }}
          ></Box>
          <Box>{t(key)}</Box>
        </Box>
      );
    }
  );

  useEffect(() => {
    if (mapRef.current || preferences.length === 0) return;

    const polygons = preferences?.map((pref) => {
      const coords = pref?.geometry?.geometries?.at(0)?.coordinates[0] || [];
      const projectedCoords = coords.map((coord) => fromLonLat(coord));
      const polygon = new Polygon([projectedCoords]);
      const feature = new Feature({
        geometry: polygon,
        sutiability: pref.sutiability,
      });
      return feature;
    });

    const vectorSource = new VectorSource({
      features: polygons,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        const sutiability = feature.get("sutiability");
        const fillColor =
          sutiabilityColors[sutiability] || "rgba(0, 0, 255, 0.7)";

        return new Style({
          fill: new Fill({
            color: fillColor,
          }),
          stroke: new Stroke({
            color: "#000000",
            width: 2,
          }),
        });
      },
    });

    const projectedBoundary = boundaries?.coordinates[0].map((coord) =>
      fromLonLat(coord)
    );

    const boundaryExtent = boundingExtent(projectedBoundary);

    const mapView = new View({
      center: [5200224.773151715, 2831517.163862219],
      zoom: 5,
    });

    mapRef.current = new Map({
      layers: [satelliteGoogle, vectorLayer],
      view: mapView,
      target: domRef?.current || undefined,
      controls: defaultControls({
        zoom: false,
        attribution: false,
        rotate: false,
      }),
    });

    mapRef.current.once("rendercomplete", () => {
      const size = mapRef.current?.getSize();
      if (size) {
        mapView.fit(boundaryExtent, {
          size: size,
          padding: [50, 50, 50, 50],
          duration: 1000,
        });
      }
    });

    mapRef.current.render();
  }, [preferences.length]);

  return (
    <Box sx={{ width: "100%", height: "600px", position: "sticky", top: 130 }}>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={domRef}
      ></div>
      <Box mt={1}>{renderedSuitabilityColors}</Box>
    </Box>
  );
};
