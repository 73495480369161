import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { EmptyData } from "@/components/EmptyData";
import { StatusChip } from "@/components/StatusChip";

export const TasksList: FC<{ tasks: any[] }> = ({ tasks }) => {
  const { t } = useTranslation();

  if (tasks.length === 0) return <EmptyData />;
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Task Number")}</TableCell>
              <TableCell>{t("External ID")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Phone number")}</TableCell>
              <TableCell>{t("Region")}</TableCell>
              <TableCell>{t("City")}</TableCell>
              <TableCell>{t("Product")}</TableCell>
              <TableCell>{t("rescheduled contact")}</TableCell>
              <TableCell>{t("Start date")}</TableCell>
              <TableCell>{t("End date")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((task) => {
              const name = task?.formData?.negotiatorData?.personalData?.name;
              const region = task?.formData?.negotiatorData?.location?.region;
              const city = task?.formData?.negotiatorData?.location?.city;
              const product =
                task?.formData?.negotiatorData?.supportPackages?.product;

              return (
                <TableRow component={NavLink} to={task.id}>
                  <TableCell>{task.id}</TableCell>
                  <TableCell>{task.beneficiary?.externalId}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.personalData?.mobileNumber}
                  </TableCell>
                  <TableCell>{region}</TableCell>
                  <TableCell>{city}</TableCell>
                  <TableCell>{product}</TableCell>
                  <TableCell>
                    <div>
                      {
                        task?.formData?.clientPreferences?.negotiatorContactTime
                          ?.contactDate
                      }
                    </div>
                    <div>
                      {
                        task?.formData?.clientPreferences?.negotiatorContactTime
                          ?.contactTime
                      }
                    </div>
                  </TableCell>
                  <TableCell>
                    {new Date(task.order.startDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(task.order.endDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <StatusChip status={task?.status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
