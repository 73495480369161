import { Loading } from "@/components/Loading";
import {
  DatePickerWidget,
  FileWidget,
  FundCalculatorWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { Button } from "@mui/material";
import { REJECT_TASK, UPDATE_TASK } from "@/shared/graphql/mutations";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import {
  fetchAllCitiesByRegionId,
  fetchAllRegions,
} from "@/shared/graphql/services/layers";
import { graphqlErrorHandler } from "@/shared/utils";
import { isCustomerService } from "@/shared/utils/mappers";
import { addWorkDays, isDateInPast } from "@/shared/utils/dates";
// import schema1 from "@/schemas/1-request.json";
// import uiSchema1 from "@/schemas/ui-schemas/1-request.json";
import { calculateFunding } from "../calaculateFunding";

export const TaskFormBeneficiaryData = ({ formKey, setFormKey }) => {
  const [formData, setFormData] = useState<any>({
    contact: {
      expectedCall: {},
    },
    request: {},
    location: {},
    familyData: {},
    personalData: {},
    financialData: {},
    supportPackages: {},
    professionalData: {},
    basicTransactionData: {},
    personalRealEstateData: {},
  });
  const { taskId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [formSchema, setFormSchema] = useState({});
  const [regions, setRegions] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canSubmit = userInfo?.isSuperuser || isCustomerService(userInfo);
  const willReject =
    formData?.contact?.willingnessToContinueProgram ===
    "لا يرغب في استكمال البرنامج";

  const { loading: taskLoading, data: taskRes } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const clonedBeneficiaryData = JSON.parse(
        JSON.stringify(
          res?.tasks?.data?.at(0)?.formData?.drafts?.beneficiaryData ||
            res?.tasks?.data?.at(0)?.formData?.beneficiaryData
        )
      );

      setFormData((prev) => ({
        ...prev,
        ...clonedBeneficiaryData,
      }));
    },
  });

  const { data, loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    skip: !taskRes,
    onCompleted: async (res) => {
      let clonedFormData = JSON.parse(
        JSON.stringify(
          taskRes?.tasks?.data?.at(0)?.formData?.drafts?.beneficiaryData ||
            taskRes?.tasks?.data?.at(0)?.formData?.beneficiaryData
        )
      );

      let requestedFromSchema = JSON.parse(
        JSON.stringify(res?.schemas?.data?.at(0)?.jsonSchema?.form)
      );
      // fetching regions
      const regionsRes = await fetchAllRegions();
      const allRegions = regionsRes?.data?.regions?.data;
      const regionsMap = regionsRes?.data?.regions?.data?.reduce(
        (hashmap, item) => {
          hashmap[item?.sourceProperties.region_name] =
            item?.sourceProperties?.id;
          return hashmap;
        },
        {}
      );
      if (requestedFromSchema?.definitions?.location?.properties?.region) {
        requestedFromSchema.definitions.location.properties.region.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }
      setRegions(regionsMap);

      // checking if the region default value exists
      const prefilledRegion = clonedFormData?.location?.region;
      if (regionsMap[prefilledRegion]) {
        // fetchCities by region id
        const citiesRes = await fetchAllCitiesByRegionId(
          regionsMap[prefilledRegion]?.toString()
        );

        const allCities = citiesRes?.data?.cities?.data;
        if (requestedFromSchema?.definitions?.location?.properties?.city) {
          requestedFromSchema.definitions.location.properties.city.enum =
            allCities?.map((city) => city.sourceProperties.city_name);
        }
      }

      // inserting installments options
      if (clonedFormData?.financialData?.installmentChoice === undefined) {
        clonedFormData.financialData.installmentChoice = null;
      }
      requestedFromSchema = insertInstallementsInFormSchema(
        requestedFromSchema,
        clonedFormData?.financialData
      );

      // add 9 days to expected call date
      clonedFormData = addDaysToExpectedCallDate(clonedFormData);

      setFormData((prev) => ({ ...prev, ...clonedFormData }));
      setFormSchema(requestedFromSchema);
    },
  });

  const [updateTask, { loading: updateTaskLoading }] = useMutation(UPDATE_TASK);
  const [rejectTaskMutate, { loading: isRejectLoading }] =
    useMutation(REJECT_TASK);

  const addDaysToExpectedCallDate = (formData) => {
    const clonedFormData = structuredClone(formData);
    const expectedCallDate =
      clonedFormData?.contact?.expectedCall?.expectedCallDate;
    const callStatus = clonedFormData?.contact?.callStatus;
    const willingnessToContinueProgram =
      clonedFormData?.contact?.willingnessToContinueProgram;
    if (expectedCallDate ? !isDateInPast(new Date(expectedCallDate)) : false)
      return clonedFormData;
    if (
      callStatus === "لم يتم الرد" ||
      willingnessToContinueProgram === "إعادة جدولة الإتصال"
    ) {
      clonedFormData.contact.expectedCall.expectedCallDate = addWorkDays(
        new Date()
      )?.toLocaleDateString("en-CA");
    }
    return clonedFormData;
  };

  const insertInstallementsInFormSchema = (formSchema, financialData) => {
    const clonedFormSchema = structuredClone(formSchema);
    const { salary, monthlyDeduction, remainingDurationMonths } = financialData;
    clonedFormSchema?.definitions?.installmentChoice?.items?.enum?.forEach(
      (choice) => {
        const {
          fundingAmount,
          monthlyInstallmentOfFundingDurationDuringPersonalInstallement,
          monthlyInstallmentOfFundingDurationAfterPersonalInstallement,
          profitMarginOfFundingDuration,
          totalAmountWithProfits,
        } = calculateFunding(
          salary,
          monthlyDeduction ?? 0,
          remainingDurationMonths ?? 0,
          choice?.properties?.fundingPeriod?.default,
          choice?.properties?.annualPercentage?.default
        );

        choice.properties.installmentAfterPersonalLoan.default =
          monthlyInstallmentOfFundingDurationAfterPersonalInstallement;
        choice.properties.installmentDuringPersonalLoan.default =
          monthlyDeduction && remainingDurationMonths
            ? monthlyInstallmentOfFundingDurationDuringPersonalInstallement
            : 0;
        choice.properties.fundingAmount.default = fundingAmount;
        choice.properties.profitMargin.default = profitMarginOfFundingDuration;
        choice.properties.totalAmountWithProfits.default =
          totalAmountWithProfits;
      }
    );

    return clonedFormSchema;
  };

  const onFormChange = async (form: any, fieldId) => {
    let clonedFormData = JSON.parse(JSON.stringify(form?.formData));
    let clonedFormSchema = JSON.parse(JSON.stringify(formSchema));
    if (fieldId === "root_location_region") {
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[form?.formData?.location?.region]?.toString()
      );

      const allCities = citiesRes?.data?.cities?.data;
      if (clonedFormSchema?.definitions?.location?.properties?.city) {
        clonedFormSchema.definitions.location.properties.city.enum =
          allCities?.map((city) => city.sourceProperties.city_name);
      }
      // setFormSchema(clonedFormSchema);
    }
    if (
      fieldId === "root_contact_callStatus" ||
      fieldId === "root_contact_willingnessToContinueProgram"
    ) {
      clonedFormData = addDaysToExpectedCallDate(clonedFormData);
    }
    if (
      [
        "root_financialData_salary",
        "root_financialData_monthlyDeduction",
        "root_financialData_remainingDurationMonths",
      ].includes(fieldId)
    ) {

      clonedFormData.financialData.installmentChoice = null;
      clonedFormSchema = insertInstallementsInFormSchema(
        clonedFormSchema,
        clonedFormData.financialData
      );
    }
    setFormData((prev) => ({ ...prev, ...clonedFormData }));
    setFormSchema(clonedFormSchema);
  };
  const onFileChange = async (data) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const saveAsDraft = () => {
    const submittedData = { beneficiaryData: formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: true,
        },
      },
    })
      .then(() => {
        navigate("../tasks");
        toast.success(t("data has been drafted"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onSubmit = (form) => {
    const submittedData = { beneficiaryData: form.formData };

    const isRejected =
      submittedData?.beneficiaryData?.contact?.willingnessToContinueProgram ===
      "لا يرغب في استكمال البرنامج";

    const willRedirectToTasks =
      isRejected ||
      submittedData?.beneficiaryData?.contact?.willingnessToContinueProgram ===
        "إعادة جدولة الإتصال" ||
      submittedData?.beneficiaryData?.contact?.callStatus === "لم يتم الرد";

    if (isRejected) {
      rejectTaskMutate({
        variables: {
          taskInput: {
            taskId,
            formSchemaKey: formKey,
            formData: JSON.stringify(submittedData),
          },
        },
      })
        .then(() => {
          navigate("../tasks");
          toast.success(t("Task Rejected Successfully"));
        })
        .catch((err) => {
          graphqlErrorHandler(err);
        });
      return;
    }
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        toast.success(t("Data has been saved successfully"));
        if (willRedirectToTasks) {
          navigate("../tasks");
          return;
        }
        setFormKey("client_preferences");
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const schema = data?.schemas?.data?.at(0)?.jsonSchema;
  const uiSchema = schema?.UISchema;
  if (schemaLoading || taskLoading || !formSchema) return <Loading />;
  const mutationLoading = updateTaskLoading || isRejectLoading;
  return (
    <Form
      formContext={{ onFileChange }}
      schema={formSchema || {}}
      uiSchema={uiSchema}
      validator={validator}
      showErrorList={false}
      onChange={onFormChange}
      formData={formData}
      experimental_defaultFormStateBehavior={{
        allOf: "populateDefaults",
      }}
      noHtml5Validate
      transformErrors={(errors) => {
        const modfiedErrors = errors?.map((err) => {
          if (
            err.name === "required" ||
            err.name === "minItems" ||
            err.name === "type"
          ) {
            return { ...err, message: "حقل مطلوب" };
          }
          if (err.name === "enum") {
            return {
              ...err,
              message: "يرجى الإختيار من القيم الموجودة",
            };
          }
          if (err.name === "if") {
            return {
              ...err,
              message: "",
            };
          }
          return err;
        });
        return modfiedErrors;
      }}
      widgets={{
        FileWidget,
        YesNoWidget,
        TextWidget,
        DateWidget: DatePickerWidget,
      }}
      fields={{ FundCalculatorWidget }}
      templates={{ ObjectFieldTemplate }}
      onSubmit={onSubmit}
    >
      <Button
        onClick={saveAsDraft}
        variant="contained"
        sx={{ mt: 2, mr: 1 }}
        disabled={!canSubmit || mutationLoading || willReject}
      >
        {t("save and complete later")}
      </Button>
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={!canSubmit || mutationLoading}
      >
        {t("save")}
      </Button>
    </Form>
  );
};
