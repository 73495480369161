import { useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {
  DatePickerWidget,
  FileWidget,
  FundCalculatorWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { UPDATE_TASK } from "@/shared/graphql/mutations";
import { InfoWidget } from "@/components/RJSF/widgets/InfoWidget/InfoWidget";
import {
  fetchAllCitiesByRegionId,
  fetchAllRegions,
} from "@/shared/graphql/services/layers";
import { SignedUrlService } from "@/shared/graphql/services/signedUrl.service";
import { graphqlErrorHandler } from "@/shared/utils";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import { Notes } from "@/components/Notes";
// import schema7 from "@/schemas/7-negotiator-beneficiary-data.json";
// import uiSchema7 from "@/schemas/ui-schemas/7-negotiator-beneficiary-data.json";
import { calculateFunding } from "@/modules/app/customer-service/Tasks/Task/TaskForm/calaculateFunding";

export const BeneficiaryForm = ({
  setSelectedStep,
  refetchTask,
  canSubmit,
}) => {
  const [formSchema, setFormSchema] = useState({});
  const [regions, setRegions] = useState({});
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const UploadSignedUrlFile = new SignedUrlService();

  const { t } = useTranslation();
  const { taskId } = useParams();
  const [updateTask, { loading: updateTaskLoading }] = useMutation(UPDATE_TASK);

  const { loading: taskLoading, data: taskRes } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const task = res?.tasks?.data?.at(0);
      if (task?.formData?.drafts?.negotiatorData) {
        setFormData((prev) => ({
          ...prev,
          ...task?.formData?.drafts?.negotiatorData,
        }));
        return;
      }

      const resFormData = JSON.parse(
        JSON.stringify(task?.formData?.negotiatorData)
      );
      resFormData.negotiatorContactTime =
        task?.formData?.clientPreferences?.negotiatorContactTime;
      setFormData((prev) => ({ ...prev, ...resFormData }));
    },
  });

  const { data, loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: "negotiation-updated-data" },
    skip: !taskRes,
    onCompleted: async (res) => {
      let requestedFromSchema = JSON.parse(
        JSON.stringify(res?.schemas?.data?.at(0)?.jsonSchema?.form)
      );
      const clonedFormData = JSON.parse(
        JSON.stringify(
          taskRes?.tasks?.data?.at(0)?.formData?.drafts?.beneficiaryData ||
            taskRes?.tasks?.data?.at(0)?.formData?.beneficiaryData
        )
      );
      // fetching regions
      const regionsRes = await fetchAllRegions();
      const allRegions = regionsRes?.data?.regions?.data;
      const regionsMap = regionsRes?.data?.regions?.data?.reduce(
        (hashmap, item) => {
          hashmap[item?.sourceProperties.region_name] =
            item?.sourceProperties?.id;
          return hashmap;
        },
        {}
      );
      if (requestedFromSchema?.definitions?.location?.properties?.region) {
        requestedFromSchema.definitions.location.properties.region.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }
      setRegions(regionsMap);

      // inserting installments options
      if (clonedFormData?.financialData?.installmentChoice === undefined) {
        clonedFormData.financialData.installmentChoice = null;
      }
      requestedFromSchema = insertInstallementsInFormSchema(
        requestedFromSchema,
        clonedFormData?.financialData
      );

      setFormSchema(requestedFromSchema);
    },
  });

  const insertInstallementsInFormSchema = (formSchema, financialData) => {
    const clonedFormSchema = structuredClone(formSchema);
    const { salary, monthlyDeduction, remainingDurationMonths } = financialData;
    clonedFormSchema?.definitions?.installmentChoice?.items?.enum?.forEach(
      (choice) => {
        const {
          fundingAmount,
          monthlyInstallmentOfFundingDurationDuringPersonalInstallement,
          monthlyInstallmentOfFundingDurationAfterPersonalInstallement,
          profitMarginOfFundingDuration,
          totalAmountWithProfits,
        } = calculateFunding(
          salary,
          monthlyDeduction ?? 0,
          remainingDurationMonths ?? 0,
          choice?.properties?.fundingPeriod?.default,
          choice?.properties?.annualPercentage?.default
        );

        choice.properties.installmentAfterPersonalLoan.default =
          monthlyInstallmentOfFundingDurationAfterPersonalInstallement;
        choice.properties.installmentDuringPersonalLoan.default =
          monthlyDeduction && remainingDurationMonths
            ? monthlyInstallmentOfFundingDurationDuringPersonalInstallement
            : 0;
        choice.properties.fundingAmount.default = fundingAmount;
        choice.properties.profitMargin.default = profitMarginOfFundingDuration;
        choice.properties.totalAmountWithProfits.default =
          totalAmountWithProfits;
      }
    );

    return clonedFormSchema;
  };

  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    let response: any;
    try {
      if (Array.isArray(data)) {
        const promises = data.map(({ file }) =>
          UploadSignedUrlFile.uploadViaSignedUrl(file, taskId as string)
        );
        response = await Promise.all(promises);
      } else {
        response = await UploadSignedUrlFile.uploadViaSignedUrl(
          data.file,
          taskId as string
        );
      }
    } catch (error) {
      console.error({ error });
    }
    return response;
  };

  const onFormChange = async (form: any, fieldId) => {
    const clonedFormData = JSON.parse(JSON.stringify(form?.formData));
    let clonedFormSchema = JSON.parse(JSON.stringify(formSchema));

    if (fieldId === "root_location_region") {
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[form?.formData?.location?.region]?.toString()
      );

      const clonedFormSchema = JSON.parse(JSON.stringify(formSchema));
      const allCities = citiesRes?.data?.cities?.data;
      if (clonedFormSchema?.definitions?.location?.properties?.city) {
        clonedFormSchema.definitions.location.properties.city.enum =
          allCities?.map((city) => city.sourceProperties.city_name);
      }
      setFormSchema(clonedFormSchema);
    }

    if (
      [
        "root_financialData_salary",
        "root_financialData_monthlyDeduction",
        "root_financialData_remainingDurationMonths",
      ].includes(fieldId)
    ) {
      clonedFormData.financialData.installmentChoice = null;
      clonedFormSchema = insertInstallementsInFormSchema(
        clonedFormSchema,
        clonedFormData.financialData
      );
    }

    setFormSchema(clonedFormSchema);
    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };

  const saveAsDraft = () => {
    const submittedData = { negotiatorData: formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: "negotiation-updated-data",
          formData: JSON.stringify(submittedData),
          isDraft: true,
        },
      },
    })
      .then(() => {
        toast.success(t("data has been drafted"));
        navigate("../tasks");
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onSubmit = (form) => {
    const submittedData = { negotiatorData: form.formData };

    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: "negotiation-updated-data",
          formData: JSON.stringify(submittedData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        refetchTask();
        toast.success(t("Data has been saved successfully"));
        setSelectedStep((prev) => prev + 1);
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  if (taskLoading) return <Loading />;

  const schema = data?.schemas?.data?.at(0)?.jsonSchema;
  const uiSchema = schema?.UISchema;
  if (schemaLoading || taskLoading || !formSchema) return <Loading />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Form
          formContext={{ onFileChange }}
          schema={formSchema || {}}
          uiSchema={uiSchema}
          validator={validator}
          onChange={onFormChange}
          noHtml5Validate
          formData={formData}
          widgets={{
            FileWidget,
            YesNoWidget,
            TextWidget,
            InfoWidget,
            DateWidget: DatePickerWidget,
          }}
          fields={{ FundCalculatorWidget }}
          templates={{ ObjectFieldTemplate }}
          onSubmit={onSubmit}
        >
          <Button
            disabled={!canSubmit || updateTaskLoading}
            onClick={saveAsDraft}
            variant="contained"
            sx={{ mt: 2, mr: 1 }}
          >
            {t("save and complete later")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={!canSubmit || updateTaskLoading}
          >
            {t("save")}
          </Button>
        </Form>
      </Grid>
      <Grid item xs={12} md={4}>
        <Notes />
      </Grid>
    </Grid>
  );
};
