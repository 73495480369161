import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "@/shared/utils";

export const FundClaculator = ({ cards, onChange, selectedOption }) => {
  const { t } = useTranslation();

  const handleCardClick = (card) => {
    onChange(card);
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      {cards?.map((card) => {
        const isSelected = selectedOption?.fundingPeriod === card.fundingPeriod;

        return (
          <Grid item xs={12} sm={6} md={4} key={card?.fundingPeriod}>
            <Card
              onClick={() => handleCardClick(card)}
              sx={{
                cursor: "pointer",
                border: isSelected
                  ? "2px solid #0000FF"
                  : "2px solid transparent",
                transition: "border 0.3s ease-in-out",
                backgroundColor: isSelected ? "#f5f5f5" : "#fff",
                "&:hover": {
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mb: 1 }}
                  >
                    {t("Monthly installment")}
                  </Typography>
                  <Box display={"flex"}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#4A148C" }}
                    >
                      {numberFormatter(card?.installmentAfterPersonalLoan)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "bold", color: "#4A148C", m: 1 }}
                    >
                      {t("SAR")}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                {card?.installmentDuringPersonalLoan ? (
                  <>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 1 }}
                      >
                        {t("Monthly installment during personal loan")}
                      </Typography>
                      <Box display={"flex"}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", color: "#4A148C" }}
                        >
                          {numberFormatter(card?.installmentDuringPersonalLoan)}{" "}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "bold", color: "#4A148C", m: 1 }}
                        >
                          {t("SAR")}
                        </Typography>{" "}
                      </Box>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                  </>
                ) : (
                  <></>
                )}
                <Box>
                  <Typography fontWeight="bold" variant="body2">
                    {t("Funding Amount")}
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#4A148C" }}
                    >
                      {numberFormatter(card?.fundingAmount)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "bold", color: "#4A148C", m: 1 }}
                    >
                      {t("SAR")}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                  <Typography fontWeight="bold" variant="body2">
                    {t("Profit margin after funding")}
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#4A148C" }}
                    >
                      {numberFormatter(card?.profitMargin)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "bold", color: "#4A148C", m: 1 }}
                    >
                      {t("SAR")}
                    </Typography>{" "}
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                  <Typography fontWeight="bold" variant="body2">
                    {t("total funding with profits")}
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#4A148C" }}
                    >
                      {numberFormatter(card?.totalAmountWithProfits)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "bold", color: "#4A148C", m: 1 }}
                    >
                      {t("SAR")}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ pb: 1 }}>
                  <Typography fontWeight="bold" variant="body2" mb={1}>
                    {t("Fund period")}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    {card?.fundingPeriod} {t("year")}
                  </Typography>
                </Box>
                <Divider />
                <Box sx={{ pt: 1 }}>
                  <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
                    {t("Annualy percentage average")}
                  </Typography>
                  <Typography
                    textAlign="center"
                    variant="h5"
                    sx={{ fontWeight: "bold", color: "#4A148C", mx: 2 }}
                  >
                    {card?.annualPercentage}%
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
