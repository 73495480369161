import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FilterSelect, FilterTag } from "@/components/Filters";
import {} from "@/_mocks/filterOptions";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_CUSTOMER_SERVICES,
  GET_ALL_NEGOTIATORS,
  GET_BENEFICIARIES_FILTER_OPTIONS,
} from "@/shared/graphql";
import { useBeneficiariesFilter } from "../useBeneficiariesFilter";
import { RangeFilter } from "@/components/Filters";

export const BeneficiariesFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const location = useLocation();

  const { isFilterKeysExist } = useBeneficiariesFilter();
  const { data } = useQuery(GET_BENEFICIARIES_FILTER_OPTIONS);
  const { t } = useTranslation();
  const renderedTags: React.ReactNode[] = [];
  const operationalMode = location.pathname.split("/").pop() as string;
  const { data: usersData } = useQuery(
    operationalMode === "negotiator"
      ? GET_ALL_NEGOTIATORS
      : GET_ALL_CUSTOMER_SERVICES,
    {
      skip: operationalMode === "unassigned",
    }
  );

  const regions = data?.beneficiaryFilterOptions?.region.map((el: string) => ({
    label: el,
    value: el,
  }));

  const cities = data?.beneficiaryFilterOptions?.city.map((el: string) => ({
    label: el,
    value: el,
  }));

  const products = data?.beneficiaryFilterOptions?.product.map(
    (el: string) => ({
      label: el,
      value: el,
    })
  );

  const jobs = data?.beneficiaryFilterOptions?.job.map((el: string) => ({
    label: el,
    value: el,
  }));

  const banks = data?.beneficiaryFilterOptions?.salaryBank.map((el: string) => {
    return {
      label: el,
      value: el,
    };
  });

  const users = usersData?.[
    operationalMode === "negotiator" ? "negotiators" : "customerServices"
  ]?.data?.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  searchParams.forEach((value, key) => {
    if (key !== "page" && key !== "name" && key !== "status") {
      let displayedvalue = value;
      let displayedKey = key;
      if (key === "orderBy") {
        if (value?.at(0) === "-") {
          displayedvalue = value.slice(1);
          displayedKey = "descending order";
        } else {
          displayedKey = "ascending order";
        }
        displayedvalue = t(displayedvalue);
      }
      if (key === "assigned_to") {
        displayedvalue = users?.find((user) => user.value === value)?.label;
      }

      renderedTags.push(
        <Box
          key={`${displayedKey} ${displayedvalue}`}
          sx={{ display: "inline-flex", mr: 1, mt: 1 }}
        >
          <FilterTag
            field={key}
            text={`${t(`filterFields.${displayedKey}`)}: `}
            displayedvalue={displayedvalue}
            value={value}
          />
        </Box>
      );
    }
  });

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={6} md={2}>
          <OutlinedInput
            fullWidth
            sx={{
              backgroundColor: "#fff",
              "&:hover": {
                outline: "#fff !important",
              },
            }}
            placeholder={t("Search by name")}
            value={searchParams.get("name") || ""}
            startAdornment={
              <InputAdornment position="end" sx={{ pr: 1 }}>
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchParams((params) => {
                params.delete("page");
                if (e.target.value) {
                  params.set("name", e.target.value);
                } else {
                  params.delete("name");
                }

                return params;
              });
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <OutlinedInput
            fullWidth
            sx={{
              backgroundColor: "#fff",
              "&:hover": {
                outline: "#fff !important",
              },
            }}
            placeholder={t("Search by mobileNumber")}
            value={searchParams.get("mobileNumber") || ""}
            startAdornment={
              <InputAdornment position="end" sx={{ pr: 1 }}>
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchParams((params) => {
                params.delete("page");
                if (e.target.value) {
                  params.set("mobileNumber", e.target.value);
                } else {
                  params.delete("mobileNumber");
                }

                return params;
              });
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FilterSelect
            isMulti
            options={regions}
            name="region"
            placeholder={t("Region")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FilterSelect
            isMulti
            options={cities}
            name="city"
            placeholder={t("City")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FilterSelect
            isMulti
            options={products}
            name="product"
            placeholder={t("Product")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FilterSelect
            isMulti
            options={jobs}
            name="job"
            placeholder={t("Job")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FilterSelect
            isMulti
            options={banks}
            name="salaryBank"
            placeholder={t("salaryBank")}
          />
        </Grid>
        {operationalMode !== "unassigned" && (
          <Grid item xs={12} sm={6} md={2}>
            <FilterSelect
              isMulti
              options={users}
              name="assigned_to"
              placeholder={t("Assigned to")}
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ mb: 1 }} display="flex">
        <RangeFilter field="age" />
        <RangeFilter field="family_member" />
        <RangeFilter field="fundingAmount" />
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="cneter">
        {renderedTags}
        {isFilterKeysExist && (
          <Button
            sx={{ mr: 1, mt: 1, p: 0 }}
            onClick={() => setSearchParams({})}
          >
            <FilterTag
              showCloseButton={false}
              field={""}
              text=""
              value=""
              color="error"
              displayedvalue={t("delete-all")}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};
