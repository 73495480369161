import { FundClaculator } from "@/components/FundCalculator/FundClaculator";
import { FieldProps } from "@rjsf/utils";

export const FundCalculatorWidget = (props: FieldProps) => {
  const { onChange, schema, formData } = props;
  const { items } = schema ?? [];

  const cards = items?.enum?.map((option: any) => ({
    fundingPeriod: option?.properties?.fundingPeriod?.default,
    installmentAfterPersonalLoan:
      option?.properties?.installmentAfterPersonalLoan?.default,
    installmentDuringPersonalLoan:
      option?.properties?.installmentDuringPersonalLoan?.default,
    fundingAmount: option?.properties?.fundingAmount?.default,
    profitMargin: option?.properties?.profitMargin?.default,
    totalAmountWithProfits: option?.properties?.totalAmountWithProfits?.default,
    annualPercentage: option?.properties?.annualPercentage?.default,
  }));

  return (
    <FundClaculator
      cards={cards}
      onChange={onChange}
      selectedOption={formData}
    />
  );
};
