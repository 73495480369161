import { useSearchParams, useLocation } from "react-router-dom";

interface Filter {
  field: string;
  clause?: string;
  value: any;
}

export const useBeneficiariesFilter = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const operationalMode = location.pathname.split("/").pop() as string;

  const isFilterKeysExist = [...searchParams.keys()].some(
    (key) => !["page", "name", "status", "mobileNumber"].includes(key)
  );

  const getFilters = (
    basePath: string,
    additionalFilters: Filter[] = []
  ): Filter[] => {
    const filters: Filter[] = [
      {
        field:
          operationalMode === "negotiator"
            ? "negotiator__id"
            : "customer_service__id",
        clause: "values_in",
        value: searchParams.getAll("assigned_to"),
      },
      {
        field: `${basePath}__professionalData__job`,
        clause: "values_in",
        value: searchParams.getAll("job"),
      },
      {
        field: `${basePath}__personalData__mobileNumber`,
        clause: "icontains",
        value: searchParams.get("mobileNumber"),
      },
      {
        field: `${basePath}__location__region`,
        clause: "values_in",
        value: searchParams.getAll("region"),
      },
      {
        field: `${basePath}__location__city`,
        clause: "values_in",
        value: searchParams.getAll("city"),
      },
      {
        field: `${basePath}__supportPackages__product`,
        clause: "values_in",
        value: searchParams.getAll("product"),
      },
      {
        field: `${basePath}__financialData__salaryBank`,
        clause: "values_in",
        value: searchParams.getAll("salaryBank"),
      },
      {
        field: `${basePath}__personalData__ageHijri`,
        clause: "gte",
        value: searchParams.get("age_from"),
      },
      {
        field: `${basePath}__personalData__ageHijri`,
        clause: "lte",
        value: searchParams.get("age_to"),
      },
      {
        field: `${basePath}__familyData__familyMembersCount`,
        clause: "gte",
        value: searchParams.get("family_member_from"),
      },
      {
        field: `${basePath}__familyData__familyMembersCount`,
        clause: "lte",
        value: searchParams.get("family_member_to"),
      },
      {
        field: `${basePath}__financialData__fundingAmount`,
        clause: "gte",
        value: searchParams.get("fundingAmount_from"),
      },
      {
        field: `${basePath}__financialData__fundingAmount`,
        clause: "lte",
        value: searchParams.get("fundingAmount_to"),
      },
    ];

    const nameFilter = searchParams.get("name");
    if (nameFilter) {
      if (operationalMode === "unassigned" && !isNaN(Number(nameFilter))) {
        filters.push({
          field: "external_id",
          clause: "icontains",
          value: nameFilter,
        });
      } else {
        filters.push(
          isNaN(Number(nameFilter))
            ? {
                field: `${basePath}__personalData__name`,
                clause: "icontains",
                value: nameFilter,
              }
            : {
                field: "beneficiary__external_id",
                clause: "icontains",
                value: nameFilter,
              }
        );
      }
    }

    return [...filters, ...additionalFilters].filter(
      (filter) => filter.value && filter.value?.length !== 0
    );
  };

  const getOrderBy = (basePath: string): string => {
    const orderByField = searchParams.get("orderBy") || "";
    if (orderByField.at(0) === "-") {
      return `-${basePath}__${orderByField.slice(1)}`;
    }
    return `${basePath}__${orderByField}`;
  };

  const getStatusBasedFilters = (): { filters: Filter[]; orderBy: string } => {
    switch (operationalMode) {
      case "unassigned":
        return {
          filters: getFilters("data"),
          orderBy: getOrderBy("data"),
        };
      case "customer-service":
        return {
          filters: getFilters("form_data__beneficiaryData", [
            { field: "assigned_status", value: "2" },
            { field: "status", clause: "values_in", value: "[1, 4]" },
          ]),
          orderBy: getOrderBy("form_data__beneficiaryData"),
        };
      case "negotiator":
        return {
          filters: getFilters("form_data__negotiatorData", [
            { field: "assigned_status", value: "3" },
          ]),
          orderBy: getOrderBy("form_data__negotiatorData"),
        };
      default:
        return { filters: [], orderBy: "" };
    }
  };

  const { filters, orderBy } = getStatusBasedFilters();

  return {
    isFilterKeysExist,
    filters,
    orderBy,
  };
};
